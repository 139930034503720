<template>
  <div>
    Page not found
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  computed: {},
  created() {}
};
</script>
